<template>
  <div>
    <button @click.prevent="openNewProduct" class="btn btn-primary mb-4">
      <i class="bi bi-plus-lg me-1 mb-1"></i>Add product
    </button>
    <NewProduct
      name="new-product-modal"
      v-model="isNewProductModalOpen"
      @cancel="closeNewProductModal"
      @confirm="onProductUpdated"
    />
    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
      @row-click="editProduct"
      styleClass="custom-table clickable"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-row="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <a
            href="#"
            @click.stop="deleteProduct(props.row)"
            style="z-index: 10"
          >
            <i class="fas fa-trash text-danger" />
          </a>
        </span>

        <!-- Column: Image -->
        <span v-if="props.column.field === 'picture'">
          <img
            v-if="props.formattedRow.picture && props.formattedRow.picture.blobSrc"
            :src="props.formattedRow.picture.blobSrc"
            width="120"
          />
        </span>

        <!-- Column: Subproducts -->
        <span v-else-if="props.column.field === 'subProducts'">
          <ul style="padding:0;">
            <li
              v-for="subProduct in props.formattedRow.subProducts"
              :key="subProduct.id"
            >
              {{ subProduct.name }} ({{ subProduct.reference }})
            </li>
          </ul>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div
          class="d-flex justify-content-between flex-wrap align-items-center"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <select
              class="form-control form-control-sm me-3"
              :value="serverParams.perPage"
              @change="
                (e) => props.perPageChanged({ currentPerPage: e.target.value })
              "
            >
              <option
                v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                :key="`select-perpage-${idx}`"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap"> products per page </span>
          </div>
          <div>
            <ul class="pagination">
              <li
                class="page-item previous"
                :class="{ disabled: serverParams.page === 1 }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page - 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="previous"></i
                ></a>
              </li>
              <div
                v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                class="d-flex flex-row align-items-center"
                :key="idx"
              >
                <li
                  class="page-item"
                  :class="{ active: serverParams.page === idx }"
                >
                  <a
                    @click.prevent="props.pageChanged({ currentPage: idx })"
                    href="#"
                    class="page-link"
                    v-if="
                      idx <= 3 ||
                      (idx >= serverParams.page - 1 &&
                        idx <= serverParams.page + 1) ||
                      idx >= Math.ceil(props.total / serverParams.perPage) - 2
                    "
                    >{{ idx }}</a
                  >
                </li>
                <p
                  class="mb-0 mt-1 mx-3"
                  v-if="
                    (idx === serverParams.page - 2 && idx > 3) ||
                    (idx === serverParams.page + 2 &&
                      idx < Math.ceil(props.total / serverParams.perPage) - 2)
                  "
                >
                  ...
                </p>
              </div>
              <li
                class="page-item next"
                :class="{
                  disabled:
                    serverParams.page ===
                    Math.ceil(props.total / serverParams.perPage),
                }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page + 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="next"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import NewProduct from "@/components/modals/products/NewProduct.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  name: "ProductsManager",
  components: {
    NewProduct,
  },
  data() {
    return {
      isNewProductModalOpen: false,
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Image",
          field: "picture",
        },
        {
          label: "Reference",
          field: "reference",
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Name",
          field: "name",
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Category",
          field: "category",
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Sub products",
          field: "subProducts",
        },
        {
          label: "Visible on marketplace",
          field: "showOnMarketplace",
          formatFn: (e) => (e ? "Yes" : "No"),
          sortable: true,
          filterOptions: {
            enabled: true,
            filterDropdownItems: [
              { value: true, text: "Yes" },
              { value: false, text: "No" },
            ],
          },
        },
        {
          label: "Price excl. VAT",
          field: "price",
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: "Stock",
          field: "stock",
          sortable: true,
          filterOptions: {
            enabled: true,
          },
        },
        // {
        //   label: "Created",
        //   field: "createdAt",
        //   sortable: true,
        //   formatFn: (dt) => new Date(dt).toLocaleDateString(),
        // },
        // {
        //   label: "Updated",
        //   field: "updatedAt",
        //   sortable: true,
        //   formatFn: (dt) => new Date(dt).toLocaleDateString(),
        // },
        {
          label: "",
          field: "action",
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "name",
          type: "asc",
        },
        page: 1,
        perPage: 25,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Products", []);
  },
  methods: {
    onProductUpdated() {
      this.fetchItemsFromApi();
      this.isNewProductModalOpen = false;
    },
    editProduct(params) {
      console.log(params.row);
      this.$vfm.show("new-product-modal", params.row);
    },
    deleteProduct(product) {
      Swal.fire({
        text: `Delete product ${product.name}?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-primary",
        },
      }).then(({ isConfirmed }) => {
        if (!isConfirmed) return;
        const config = {
          responseToast: {
            text: "Product deleted successfully.",
          },
        };
        ApiService.delete(`products/${product.id}`, config).then(() => {
          this.rows = this.rows.filter((e) => e.id !== product.id);
        });
      });
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log("onPageChange", params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (["showOnMarketplace", "price", "stock"].includes(e)) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: 'subProducts',
      };

      ApiService.query("/products", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;

          // Fetch products' images
          const imagesBlobsPromises = this.rows.map((product) => {
            if (!product.picture || !product.picture.filename) return null;
            return ApiService.fetchImageWithAuth(`/products/files/${product.picture.filename}`);
          });

          Promise.all(imagesBlobsPromises).then(imagesBlobs => {
            for (let i = 0; i < this.rows.length; i++) {
              if (!imagesBlobs[i]) continue;
              this.rows[i].picture['blobSrc'] = imagesBlobs[i];
            }
          });
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    openNewProduct(customProduct) {
      this.$vfm.show("new-product-modal", customProduct || this.product);
    },
    closeNewProductModal() {
      this.isNewProductModalOpen = false;
    },
    onProductCreated(createdProduct) {
      this.$vfm.hide("new-product-modal");
      this.$router.push({ name: "product", params: { id: createdProduct.id } });
    },
  },
};
</script>