<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        {{ product.id ? "Edit" : "New" }} product
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Product Details</h5>
      <form @submit.prevent="saveProduct" ref="form">
        <div class="d-flex flex-row row mb-12">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-6 mb-5">
            <label for="reference">Reference</label>
            <input
              type="text"
              class="form-control"
              id="reference"
              v-model="product.reference"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label>Show on Marketplace</label>
            <div
              class="
                form-check form-switch form-check-custom form-check-solid
                mt-2
              "
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="showonmarketplace"
                v-model="product.showOnMarketplace"
              />
              <label class="form-check-label" for="showonmarketplace"
                >Show</label
              >
            </div>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="firstname">Name</label>
            <input
              type="text"
              class="form-control"
              id="name"
              v-model="product.name"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="category">Category</label>
            <input
              type="text"
              class="form-control"
              id="category"
              v-model="product.category"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="price">Price excl. VAT</label>
            <input
              type="number"
              step="0.01"
              min="0"
              class="form-control"
              id="price"
              v-model="product.price"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="stock">
              Stock quantity
              <small>— Automatically determined if sub-products are selected</small>
            </label>
            <input
              type="number"
              step="1"
              min="0"
              class="form-control"
              id="stock"
              v-model="product.stock"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="image">Image (jpg, max 2 Mo)</label>
            <input
              id="image"
              ref="fileupload"
              :disabled="isUploading"
              @change="handleFileUpload($event.target.files[0])"
              type="file"
              class="form-control"
              accept="image/jpeg"
            />
          </div>
          <div class="d-flex flex-column col-12 mb-5">
            <label>
              Sub products
              <small>— If this product is a group of existing products</small>
            </label>
            <v-select
              v-model="product.subProducts"
              label="name"
              :options="products"
              :reduce="(e) => e.id"
              @option:selected="onSubProductsChange"
              @option:deselected="onSubProductsChange"
              multiple
            >
              <template #no-options>Search by name...</template>
              <template #option="option">
                <div class="d-center">
                  {{ option.name }} ({{ option.reference }})
                </div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">
                  {{ option.name }} ({{ option.reference }})
                </div>
              </template>
            </v-select>
          </div>
          <!-- End::Right col -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', product)"
        >Confirm</a
      > -->
      <a
        class="btn btn-lg btn-primary me-2"
        :disabled="isUploading"
        @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import _ from "lodash";

import ApiService from "@/core/services/ApiService";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  emits: ["confirm", "cancel"],
  components: {
    vSelect,
  },
  data() {
    return {
      products: [],
      isUploading: false,
      image: null,
      product: {
        reference: "",
        name: "",
        category: "",
        price: "",
        stock: "",
        showOnMarketplace: false,
        subProducts: [],
      },
    };
  },
  methods: {
    fetchProductsList() {
      ApiService.query("/products", { params: { limit: 200 } }).then(({ data }) => {
        this.products = data.results;
      });
    },
    onSubProductsChange() {
      if (!this.product.subProducts.length) {
        this.product.stock = 0;
        return;
      }

      let minStock = 9999999;
      for (const subProductId of this.product.subProducts) {
        const subProduct = this.products.find(product => product.id == subProductId);
        if (subProduct && subProduct.stock < minStock) minStock = subProduct.stock;
      }
      this.product.stock = minStock;
    },
    async handleFileUpload(file) {
			this.isUploading = true;
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				alert("Only jpeg and png images are allowed");
				this.isUploading = false;
				return;
			}
			if (file.size > 2 * 1000000) { // Max. 2Mo
				alert("File too large, max. 2Mo");
				this.isUploading = false;
				return;
			}
			this.image = file;
			this.isUploading = false;
    },
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    saveProduct() {
      if (this.product.id) {
        const config = {
          responseToast: {
            text: "Product updated successfully.",
          },
        };

        // JSON to FormData (to handle image)
        const formData = new FormData();
        if (this.image) formData.append("image", this.image);
        for (const key in this.product) {
          if (key == "subProducts") {
            for (const subProduct of this.product.subProducts) {
              console.log("subProduct PROCESS -> ", subProduct);
              formData.append("subProducts[]", subProduct);
            }
          } else {
            formData.append(key, this.product[key]);
          }
        }

        ApiService.update("/products", this.product.id, formData, config)
          .then(({ data }) => {
            this.image = null;
            this.$refs.fileupload.value = null;
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const config = {
          responseToast: {
            text: "Product created successfully.",
          },
        };

        // JSON to FormData (to handle image)
        const formData = new FormData();
        if (this.image) formData.append("image", this.image);
        for (const key in this.product) {
          if (key == "subProducts") {
            for (const subProduct of this.product.subProducts) {
              formData.append("subProducts[]", subProduct);
            }
          } else {
            formData.append(key, this.product[key]);
          }
        }

        ApiService.post("/products", formData, config)
          .then(({ data }) => {
            console.log(data);
            this.image = null;
            this.$refs.fileupload.value = null;
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    processParams(existingProduct) {
      this.fetchProductsList(); // For packages
      
      if (existingProduct) {
        this.product = _.cloneDeep(existingProduct);
        delete this.product.image;
        delete this.product.picture;

        // Parse products for v-select (id-based)
        if (this.product.subProducts) {
          this.product.subProducts = this.product.subProducts.map(({ id }) => id);
        }
      }
    },

    // onSearch(search, loading) {
    //   if (search.length) {
    //     loading(true);
    //     this.search(loading, search, this);
    //   }
    // },
    // search: _.debounce((loading, search, vm) => {
    //   ApiService.query("/products", {
    //     params: {
    //       reference_contains: search,
    //       name_contains: search,
    //       _or: 1,
    //     },
    //   })
    //     .then((res) => {
    //       vm.products = res.data.results;
    //       loading(false);
    //     })
    //     .catch((err) => {
    //       vm.products = [];
    //       loading(false);
    //     });
    // }, 350),
  },
};
</script>

<style lang="scss" scoped>
// .modal__content {
//     overflow-y: visible !important;
// }
</style>
